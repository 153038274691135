import React from 'react';
import { FaBuilding } from 'react-icons/fa';
import { useIntl } from 'gatsby-plugin-intl';
import ContactInfo from './ContactInfo';
import GoogleMap from './GoogleMap';

const containerStyle = {
    width: '100%',
    height: '325px',
    borderRadius: '10px',
};

const Office = () => {
    const intl = useIntl();
    const { locale } = intl;
    const openingHours = locale === 'hu' && intl.formatMessage({ id: 'contact.openingHours' });

    return (
        <section id="office">
            <div className="container">
                <h2 className="section-title">{intl.formatMessage({ id: 'navigation.office' })}</h2>
                <GoogleMap containerStyle={containerStyle} />
                <div className="office-details row">
                    <div className="col-sm-12 office-details__col" id="contact">
                        <ContactInfo oneLiner={true} />
                        {openingHours && (
                            <div className="row contact-row contact-opening-hours">
                                <div className="col-sm-12 contact-title">
                                    <FaBuilding className="contact-icons" />{' '}
                                    <strong>{intl.formatMessage({ id: 'openingHours' })}</strong>
                                </div>
                                <div className="col-sm-12">{openingHours}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Office;
