import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import JSONData from '../content/data.json';

const { partners } = JSONData;

const Partners = () => {
    const intl = useIntl();
    const { imgStatika, imgMihavetz } = useStaticQuery(graphql`
        query PartnersImage {
            imgStatika: file(relativePath: { eq: "statika-logo.png" }) {
                childImageSharp {
                    fixed(width: 250, height: 125, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            imgMihavetz: file(relativePath: { eq: "mihavetz-logo.png" }) {
                childImageSharp {
                    fixed(width: 125, height: 125, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
        }
    `);

    return (
        <section id="partners">
            <div className="container">
                <h2 className="section-title">{intl.formatMessage({ id: `navigation.partners` })}</h2>
                <div className="row partners">
                    <div className="col-sm-12 col-md-6">
                        <a href={partners.statika} target="_blank" rel="noreferrer">
                            <div className="partner-logo">
                                <Img
                                    alt={intl.formatMessage({ id: `partners.statika2` })}
                                    fixed={imgStatika.childImageSharp.fixed}
                                />
                            </div>
                            <span className="partner-title">{intl.formatMessage({ id: `partners.statika` })}</span>
                        </a>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <a href={partners.mihavetz} target="_blank" rel="noreferrer">
                            <div className="partner-logo">
                                <Img
                                    alt={intl.formatMessage({ id: `partners.mihavetz2` })}
                                    fixed={imgMihavetz.childImageSharp.fixed}
                                />
                            </div>
                            <span className="partner-title">{intl.formatMessage({ id: `partners.mihavetz` })}</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;
