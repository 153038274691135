import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';

const Services = () => {
    const intl = useIntl();
    const { imgServices, imgServicesTwo } = useStaticQuery(graphql`
        query ServiceImage {
            imgServices: file(relativePath: { eq: "135.JPG" }) {
                childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            imgServicesTwo: file(relativePath: { eq: "P1160010.JPG" }) {
                childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);
    return (
        <section id="services">
            <div className="container">
                <h2 className="section-title">{intl.formatMessage({ id: `whatWeDo` })}</h2>
                <div className="service-container">
                    <div className="row">
                        <article>
                            <div className="col-sm-12 col-md-8">
                                <div className="about-content">
                                    <ul>
                                        <li>
                                            {intl.formatMessage({ id: `services.economical_and_commercial_law` })}
                                            <ul>
                                                <li>
                                                    {intl.formatMessage({
                                                        id: `services.corporate_contracts_and_regulations`,
                                                    })}
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            {intl.formatMessage({ id: `services.civil_law` })}
                                            <ul>
                                                <li>
                                                    {intl.formatMessage({
                                                        id: `services.general_terms_and_conditions`,
                                                    })}
                                                </li>
                                                <li>{intl.formatMessage({ id: `services.webshops` })}</li>
                                                <li>{intl.formatMessage({ id: `services.lien` })}</li>
                                                <li>{intl.formatMessage({ id: `services.enforcement_of_claims` })}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            {intl.formatMessage({ id: `services.labor_law` })}
                                            <ul>
                                                <li>
                                                    {intl.formatMessage({
                                                        id: `services.labor_contracts_and_regulations`,
                                                    })}
                                                </li>
                                                <li>
                                                    {intl.formatMessage({ id: `services.litigation_representation` })}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="about-photo right-align">
                                    <Img className="about-img" fluid={imgServices.childImageSharp.fluid} alt="" />
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="row">
                        <article>
                            <div className="col-sm-12 col-md-4 hidden-sm">
                                <div className="about-photo left-align">
                                    <Img className="about-img" fluid={imgServicesTwo.childImageSharp.fluid} alt="" />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-8">
                                <ul>
                                    <li>
                                        {intl.formatMessage({ id: `services.construction_and_warranty` })}
                                        <ul>
                                            <li>{intl.formatMessage({ id: `services.advisory_services_related` })}</li>
                                            <li>{intl.formatMessage({ id: `services.forensics_expert_reviews` })}</li>
                                            <li>
                                                {intl.formatMessage({
                                                    id: `services.special_disciplinary_experts`,
                                                })}
                                            </li>
                                            <li>
                                                {intl.formatMessage({
                                                    id: `services.special_engineering_solutions`,
                                                })}
                                            </li>
                                        </ul>
                                    </li>
                                    <li>{intl.formatMessage({ id: `services.real_estate` })}</li>
                                    <li>
                                        {intl.formatMessage({ id: `services.data_protection` })}
                                        <ul>
                                            <li>{intl.formatMessage({ id: `services.creating_regulations` })}</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
