import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import ContactInfo from './ContactInfo';

const Hero = () => {
    const intl = useIntl();
    const { mobileImageOffice, desktopImageOffice } = useStaticQuery(graphql`
        query HeroImages {
            mobileImageOffice: file(relativePath: { eq: "amalia.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            desktopImageOffice: file(relativePath: { eq: "amalia.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    return (
        <section id="home" className="hero">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1>{intl.formatMessage({ id: `profile.name` })}</h1>
                                <h2 className="job-title">{intl.formatMessage({ id: `lawyer` })}</h2>
                                <p className="description">{intl.formatMessage({ id: `profile.description` })}</p>
                            </div>
                        </div>
                        <div>
                            <ContactInfo />
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary hero-cta" href="#contact" role="button">
                                        {intl.formatMessage({ id: `contactMe` })}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-7 hero-content">
                        <Img
                            className="avatar"
                            fluid={[
                                mobileImageOffice.childImageSharp.fluid,
                                {
                                    ...desktopImageOffice.childImageSharp.fluid,
                                    media: `(min-width: 900px)`,
                                },
                            ]}
                            alt="Ügyvédi iroda Pannonhalma, Váralja 2."
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Hero;
