import React from 'react';
import useInViewPort from './UseInViewport';

const GoogleMap = ({ containerStyle }) => {
    const [nodeRef, isVisible] = useInViewPort();
    return (
        <div ref={nodeRef}>
            {isVisible && (
                <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.9450526608475!2d19.024601915383002!3d47.49098377917717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc2f01682071%3A0x2f42cb161d58c7bd!2zQnVkYXBlc3QsIEd5xZFyaSDDunQgMTgsIDExMjM!5e0!3m2!1shu!2shu!4v1606136281929!5m2!1shu!2shu"
                    style={containerStyle}
                    frameBorder="0"
                    allowFullScreen=""
                    aria-hidden="false"
                />
            )}
        </div>
    );
};

export default GoogleMap;
