import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { useIntl } from 'gatsby-plugin-intl';
import JSONData from '../content/data.json';

const { profile } = JSONData;

const ContactInfo = ({ oneLiner = false }) => {
    const classNames = {
        contactTitle: oneLiner ? 'col-sm-12 contact-title' : 'col-sm-12 col-md-4 contact-title',
        contactValue: oneLiner ? 'col-sm-12' : 'col-sm-12 col-md-8',
    };
    const rowClassNames = oneLiner ? 'col-sm-12 col-md-4 contact-row' : 'row contact-row';
    const containerClass = oneLiner ? 'row' : null;

    const intl = useIntl();

    return (
        <div className={containerClass}>
            <div className={rowClassNames}>
                <div className={classNames.contactTitle}>
                    <FaPhone className="contact-icons" /> <strong>{intl.formatMessage({ id: 'contact.phone' })}</strong>
                </div>
                <div className={classNames.contactValue}>
                    <a href={`tel:${profile.contact.phone}`}>{profile.contact.formattedPhone}</a>
                </div>
            </div>
            <div className={rowClassNames}>
                <div className={classNames.contactTitle}>
                    <FaEnvelope className="contact-icons" />{' '}
                    <strong>{intl.formatMessage({ id: 'contact.email' })}</strong>
                </div>
                <div className={classNames.contactValue}>
                    <a href={`mailto:${profile.contact.email}`}>{profile.contact.email}</a>
                </div>
            </div>
            <div className={rowClassNames}>
                <div className={classNames.contactTitle}>
                    <FaMapMarkerAlt className="contact-icons" />{' '}
                    <strong>{intl.formatMessage({ id: 'contact.address' })}</strong>
                </div>
                <div className={classNames.contactValue}>
                    <span>{profile.contact.address}</span>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
