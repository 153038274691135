import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';

const About = () => {
    const intl = useIntl();
    const { imgAbout, imgAboutTwo } = useStaticQuery(graphql`
        query AboutImages {
            imgAbout: file(relativePath: { eq: "P1150960.JPG" }) {
                childImageSharp {
                    fluid(maxWidth: 425, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            imgAboutTwo: file(relativePath: { eq: "rep6.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 425, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    return (
        <section id="about" className="about">
            <div className="container">
                <h2 className="section-title">{intl.formatMessage({ id: `navigation.about` })}</h2>
                <div className="row">
                    <article>
                        <div className="col-sm-12 col-md-8">
                            <div className="about-content">
                                <p className="description">{intl.formatMessage({ id: `about-part1` })}</p>
                                <p className="description">{intl.formatMessage({ id: `about-part2` })}</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="about-photo right-align">
                                <Img className="about-img" fluid={imgAbout.childImageSharp.fluid} alt="" />
                            </div>
                        </div>
                    </article>
                </div>
                <div className="row">
                    <article>
                        <div className="col-sm-12 col-md-4 hidden-sm">
                            <div className="about-photo left-align">
                                <Img className="about-img" fluid={imgAboutTwo.childImageSharp.fluid} alt="" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <p className="description">{intl.formatMessage({ id: `about-part3` })}</p>
                            <p className="description">{intl.formatMessage({ id: `about-part4` })}</p>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    );
};

export default About;
