import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { FaBars, FaTimes } from 'react-icons/fa';
import LanguageSwitcher from './LanguageSwitcher';
import JSONData from '../content/data.json';

const {
    navigation: { menu, cta },
    profile: { name },
} = JSONData;

const Header = () => {
    const intl = useIntl();
    const [isOpen, setOpen] = useState(false);

    let menuClass = 'menu navbar-nav';
    if (isOpen) {
        menuClass += ' active';
    }

    return (
        <header>
            <nav className="nav">
                <div className="nav-bar container">
                    <div className="navbar-brand">
                        <span>{name}</span>
                        {/* <span className="sub-title">{intl.formatMessage({ id: `lawyer` })}</span> */}
                    </div>
                    <div className="toggle">
                        <a
                            href="#0"
                            onClick={() => setOpen(!isOpen)}
                            className="menu-icon"
                            aria-label={isOpen ? 'Menü bezárása' : 'Menü kinyitása'}
                        >
                            {isOpen ? <FaTimes size="2em" /> : <FaBars size="2em" />}
                        </a>
                    </div>
                    <div className="navbar-collapse">
                        <ul className={menuClass}>
                            {menu.map(({ key, url }) => (
                                <li className="item" key={key}>
                                    <a href={url}>{intl.formatMessage({ id: `navigation.${key}` })}</a>
                                </li>
                            ))}
                            <li className="item">
                                <LanguageSwitcher />
                            </li>
                            <li className="item cta">
                                <a className="btn btn-primary" href={cta.url} role="button">
                                    {intl.formatMessage({ id: `navigation.${cta.key}` })}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
