import React, { useState, useRef, useLayoutEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { changeLocale, useIntl } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';
import { FaAngleDown } from 'react-icons/fa';

const langToChange = {
    hu: 'Magyar',
    en: 'English',
    de: 'German',
};

const LanguageSwitcher = ({ direction = 'bottom' }) => {
    const { locale } = useIntl();
    const currentLabel = langToChange[locale];
    const [isOpen, setOpen] = useState(false);
    const languageList = useRef(null);
    const focusedElBeforeOpen = useRef(null);
    const firstUpdate = useRef(true);

    useLayoutEffect(() => {
        const handleClick = ({ target }) => {
            if (!(target === languageList.current || languageList.current.contains(target))) {
                setOpen(false);
            }
        };
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isOpen) {
            languageList.current.children[0].querySelector('a').focus();
            window.addEventListener('click', handleClick);
        } else {
            window.removeEventListener('click', handleClick);
            focusedElBeforeOpen.current.focus();
        }

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [isOpen]);

    const { flagEn, flagHu, flagDe } = useStaticQuery(graphql`
        query FlagImages {
            flagEn: file(relativePath: { eq: "flags/united-kingdom.png" }) {
                childImageSharp {
                    fixed(width: 25, height: 25, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            flagHu: file(relativePath: { eq: "flags/hungary.png" }) {
                childImageSharp {
                    fixed(width: 25, height: 25, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            flagDe: file(relativePath: { eq: "flags/germany.png" }) {
                childImageSharp {
                    fixed(width: 25, height: 25, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
        }
    `);

    const handleChangeLang = (event, target) => {
        event.preventDefault();
        changeLocale(target);
    };

    const handleButtonKeyDown = event => {
        switch (event.key) {
            case 'Enter':
            case 'ArrowDown':
                event.preventDefault();
                setOpen(true);
                break;
            case 'Escape':
                event.preventDefault();
                setOpen(false);
                break;
            default:
        }
    };

    const handleItemKeyDown = (event, target) => {
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                focusNextElement(event.target);
                break;
            case 'ArrowUp':
                event.preventDefault();
                focusPreviousElement(event.target);
                break;
            case 'Enter':
                event.preventDefault();
                event.stopPropagation();
                changeLocale(target);
                break;
            case 'Escape':
                setOpen(false);
                break;
            default:
        }
    };

    const focusNextElement = ({ parentElement }) => {
        const nextSiblingElement = parentElement.nextElementSibling;
        if (nextSiblingElement) {
            nextSiblingElement.querySelector('a').focus();
        }
    };

    const focusPreviousElement = ({ parentElement }) => {
        const prevSiblingElement = parentElement.previousElementSibling;
        if (prevSiblingElement) {
            prevSiblingElement.querySelector('a').focus();
        }
    };

    const getFlag = langCode => {
        switch (langCode) {
            case 'de':
                return flagDe;
            case 'en':
                return flagEn;
            case 'hu':
            default:
                return flagHu;
        }
    };

    let slNavClass = 'sl-nav';
    if (isOpen) {
        slNavClass += ' sl-nav--opened';
    }

    return (
        <div className={slNavClass}>
            <a
                href="#0"
                className="selected-lang"
                ref={focusedElBeforeOpen}
                aria-expanded={isOpen}
                aria-label={`Select your language, Currently selected: ${currentLabel}`}
                onKeyDown={handleButtonKeyDown}
            >
                <Img className="flag" fixed={getFlag(locale).childImageSharp.fixed} alt="" />
                <span className="current-lang">{currentLabel}</span>
                <FaAngleDown />
            </a>{' '}
            <i className="fa fa-angle-down" aria-hidden="true"></i>
            <div className={`triangle triangle--${direction}`}></div>
            <ul
                className={`sl-nav__lang-list sl-nav__lang-list--${direction}`}
                tabIndex="-1"
                aria-label="Select your language"
                ref={languageList}
            >
                {Object.keys(langToChange).map(langKey => (
                    <li key={langKey}>
                        <a
                            className={locale === langKey ? 'active' : null}
                            href={`/${langKey}`}
                            onKeyDown={event => handleItemKeyDown(event, langKey)}
                            onClick={event => handleChangeLang(event, langKey)}
                        >
                            <i className="sl-flag">
                                <Img className="flag" fixed={getFlag(langKey).childImageSharp.fixed} alt="" />
                            </i>{' '}
                            <span>{langToChange[langKey]}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LanguageSwitcher;
