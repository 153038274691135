import { useEffect, useState, useRef } from 'react';

const options = {
    rootMargin: '300px 0px 300px 0px',
    threshold: 0,
};

export default function useInViewPort() {
    let nodeRef = useRef(null);
    let observerRef = useRef(null);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(entries => {
            setIsVisible(entries[0].isIntersecting);
        }, options);

        observerRef.current.observe(nodeRef.current);

        return () => {
            observerRef.current.disconnect();
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            observerRef.current.disconnect();
        }
    }, [isVisible]);

    return [nodeRef, isVisible];
}
