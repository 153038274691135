import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Helmet from 'react-helmet';
import JSONData from '../content/data.json';

const { profile, siteUrl } = JSONData;

const SEO = () => {
    const intl = useIntl();
    const { locale, defaultLocale } = intl;
    const url = defaultLocale === locale ? siteUrl : `${siteUrl}/${locale}`;
    const imageUrl = `${url}/img.jpg`;

    return (
        <Helmet>
            <html lang={locale} />
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

            <title>{intl.formatMessage({ id: 'seo.title' })}</title>
            {url && <meta name="identifier-url" content={url} />}

            <link rel="canonical" href={url} />

            <meta name="title" content={intl.formatMessage({ id: 'seo.title' })} />
            <meta name="description" content={intl.formatMessage({ id: 'seo.description' })} />
            <meta name="keywords" content={intl.formatMessage({ id: 'seo.keywords' })} />
            <meta name="author" content={profile.name} />

            <meta property="og:type" content="profile" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={profile.name} />
            <meta property="og:description" content={intl.formatMessage({ id: 'seo.description' })} />
            <meta property="og:image" content={imageUrl} />
            <meta property="profile:first_name" content={profile.firstName} />
            <meta property="profile:last_name" content={profile.lastName} />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content={url} />
            <meta name="twitter:title" content={intl.formatMessage({ id: 'seo.title' })} />
            <meta name="twitter:description" content={intl.formatMessage({ id: 'seo.description' })} />
            <meta name="twitter:image" content={imageUrl} />

            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />

            <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org",
                        "@type": "LegalService",
                        "name": "${profile.name}",
                        "url": "${url}",
                        "image": "${imageUrl}",
                        "telephone": "${profile.contact.phone}",
                        "address": {
                          "@type": "PostalAddress",
                          "streetAddress": "Győri út 18.",
                          "addressLocality": "Budapest",
                          "postalCode": "1123",
                          "addressCountry": "HU"
                        }
                      }
                `}</script>
        </Helmet>
    );
};

export default SEO;
