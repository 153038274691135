import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import LanguageSwitcher from './LanguageSwitcher';
import JSONData from '../content/data.json';

const {
    profile,
    navigation: { menu, cta },
} = JSONData;

const Footer = () => {
    const intl = useIntl();
    return (
        <footer className="footer">
            <section id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <span className="footer-copyright">
                                © {profile.name} {profile.title} {new Date().getFullYear()}
                            </span>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <ul className="footer-nav">
                                {menu.map(({ key, url }) => (
                                    <li className="nav-item" key={key}>
                                        <a href={url}>{intl.formatMessage({ id: `navigation.${key}` })}</a>
                                    </li>
                                ))}
                                <li className="nav-item lang-switcher-container">
                                    <LanguageSwitcher direction="top" />
                                </li>
                                <li className="nav-item">
                                    <a href={cta.url}>{intl.formatMessage({ id: `navigation.${cta.key}` })}</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-12 impressum">
                            <p>Impresszum</p>
                            <p>
                                Dr. Nagy-Pál Amália, a Budapesti Ügyvédi Kamarában bejegyzett ügyvéd tartja fenn ezt a
                                honlapot az ügyvédekre vonatkozó jogszabályok és belső szabályzatok szerint, melyek az
                                ügyféljogokra vonatkozó tájékoztatással együtt a{' '}
                                <a href="http://www.magyarugyvedikamara.hu" target="_blank" rel="noreferrer">
                                    www.magyarugyvedikamara.hu
                                </a>{' '}
                                honlapon találhatóak.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
};

export default Footer;
